export const platformDetails = `query platform($ownerId: Int!, $labelId: Int!) {  
    platform(ownerId: $ownerId, labelId: $labelId) {
      id
      platform_name
      subdomain
      featured_offer
      barrier_survey_enabled
      frequency_survey_enabled
      section_behavior
      section_ids
      primary_colour
      secondary_colour
      button_colour
      button_text_colour      
      bg_color         
      logo_src
      login_bg_src
      promo_page_title
      promo_page_description
      promo_page_contact_email
      footer_message
      show_activation_status
      show_mortgage_answers
      show_rating_answers
      show_access_type
      allow_csv_export
    }
  }
  `;

export const messagingConfig = `query messagingConfig($account_id: Int!) {  
    messagingConfig(account_id: $account_id) {
        id    
        account_id
        send_activation_email_1
        send_activation_email_2
        send_activation_email_3
        password_reset_enabled
        send_order_confirmation
      }
    }
    `;

export const allTerms = `query terms($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
      terms(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
        id
        agency {
          account_name
        }
        new_plan
        terms_url
        signed_at
      }
    }
    `;
