import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { createTermMutation } from "./../graphql/mutations/systemMutations";
import useBaseNavigation from "./../hooks/baseNavigation";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import { TERM_MODEL_NAME } from "./../configs/constants";
import PageSpinner from "./../mood-ui/PageSpinner";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "./../mood-ui/Layout";
import { FormControls, SaveButton, CancelButton } from "./../mood-ui/Controls";
import { Heading } from "./../mood-ui/Typography";
import {
  SelectField,
  TextField,
  ToggleCheckboxField,
} from "./../mood-ui/Fields";
import useDataProvider from "../hooks/dataProvider";

const CREATE_TERM = gql(createTermMutation);

const TermCreate = () => {
  const { protectPage } = usePermissions();
  protectPage(Permissions.Mutation.CreateRole);
  const { fieldError, resolveMutationErrors, clearError } = useFormErrors();
  const { goBack } = useBaseNavigation(TERM_MODEL_NAME, {
    urlContext: "settings",
  });

  const [landlords] = useDataProvider("landlords");

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    agency_id: 0,
    new_plan: "",
    terms_url: "",
  });

  const { setFieldValue: handleFieldOnChange } = useBaseFieldSetters(
    formData,
    stateMethods,
    clearError
  );

  const [
    createRole,
    { data: createResult, loading: isCreating, error: createError },
  ] = useMutation(CREATE_TERM, {
    variables: { ...formData },
    onCompleted: (result) => {
      goBack();
    },
    onError: (e) => {
      resolveMutationErrors(e);
    },
  });

  const handleSave = () => {
    const data = { ...formData };
    createRole({ variables: data });
  };

  if (!formData || !landlords) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Create a Terms of Use for client"
              description="Those terms will appear on the Vaboo client admin application and ask client to agree with the new Terms of Use"
            />
          </Col>
        </Row>
        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-8 mx-5">
              <Col width={4}>
                <SelectField
                  value={formData.agency_id}
                  values={landlords}
                  label="Client"
                  name="agency_id"
                  onChange={handleFieldOnChange}
                  valueKey="id"
                  labelKey="account_name"
                  prependOption="Select associated client"
                  error={fieldError("agency_id")}
                  isRequired={true}
                  description="The client that must accept this terms"
                />
              </Col>
            </Row>
            <Row tweaks="border-b border-[#dfe2e8] py-8 mx-5">
              <Col width={4}>
                <TextField
                  loading={!formData}
                  name="new_plan"
                  label="Plan"
                  value={formData?.new_plan}
                  onChange={handleFieldOnChange}
                  error={fieldError("new_plan")}
                  isRequired={true}
                />
              </Col>
              <Col width={8}>
                <TextField
                  loading={!formData}
                  name="terms_url"
                  label="Terms Url"
                  value={formData?.terms_url}
                  onChange={handleFieldOnChange}
                  error={fieldError("terms_url")}
                  isRequired={true}
                />
              </Col>
            </Row>
          </FormData>
          <FormControls>
            <CancelButton onClick={goBack} />
            <SaveButton onClick={handleSave} loading={isCreating} />
          </FormControls>
        </FormContainer>
      </BlockContainer>
    </PageContainer>
  );
};

export default TermCreate;
