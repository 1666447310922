import React from "react";

const loadingCss =
  "text-transparent bg-gradient-to-r from-purple-100 via-purple-50 to-purple-100 animate-pulse opacity-50 bg-white border border-purple-200";

export const Heading = ({
  text,
  description = false,
  size = "xl",
  inset = false,
}) => (
  <div className={inset ? "sborder-l-2 border-purple-300 -mx-5 px-5" : " "}>
    <h3 className={`text-${size} leading-7 text-gray-700 select-none`}>
      {text}
    </h3>
    {description && (
      <p className="mt-1 text-sm leading-5 text-gray-400 select-none">
        {description}
      </p>
    )}
  </div>
);

export const Text = ({ text }) => <span>{text}</span>;

export const ColorLabel = ({ text, color = "gray" }) => (
  <span
    className={`px-2 inline-flex text-xs leading-5 uppercase w-full justify-center bg-${color}-200 text-${color}-500`}
  >
    {text}
  </span>
);

export const ItemsNotFound = ({ text, description = false, tweaks = "" }) => (
  <div className={"text-center px-4 py-16 leading-5 select-none " + tweaks}>
    <h3 className="text-md leading-2 text-gray-500">{text}</h3>
    {description && (
      <p className="mt-2 text-sm leading-2 text-gray-500">{description}</p>
    )}
    {!description && (
      <p className="mt-2 text-sm leading-5 text-gray-400">
        Nothing found using current search criteria
      </p>
    )}
  </div>
);

export const BoxedMessage = ({ text, description = false }) => (
  <center>
    <div className="mt-8 px-7 py-7 rounded bg-color-gray-100 leading-5 border select-none">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{text}</h3>
      {description && (
        <p className="mt-1 text leading-5 text-gray-500">{description}</p>
      )}
    </div>
  </center>
);

export const InfoMessage = ({ text, icon = true, loading = false }) =>
  loading ? (
    <div className="flex-col">
      <div className={`mb-1 ${loadingCss}`}>asd</div>
      <div className={`mb-1 ${loadingCss}`}>asd</div>
      <div className={`mb-1 ${loadingCss}`}>asd</div>
    </div>
  ) : (
    <div className="border border-blue-400 bg-blue-50 p-4 select-none">
      <div className="flex">
        {icon && (
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-blue-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
        <div
          className={
            "flex-1 md:flex md:justify-between " + (icon ? " ml-3" : " px-0.5")
          }
        >
          <p className="text-sm leading-6 text-blue-700">{text}</p>
        </div>
      </div>
    </div>
  );

export const ColorMessage = ({ text, color = "blue", loading = false }) =>
  loading ? (
    <div className="flex-col">
      <div className={`mb-1 ${loadingCss}`}>asd</div>
      <div className={`mb-1 ${loadingCss}`}>asd</div>
      <div className={`mb-1 ${loadingCss}`}>asd</div>
    </div>
  ) : (
    <div
      className={`border border-${color}-400 bg-${color}-50 p-4 select-none`}
    >
      <div className="flex">
        <div className="flex-1 md:flex md:justify-between px-0.5">
          <p className={`text-sm leading-6 text-${color}-700`}>{text}</p>
        </div>
      </div>
    </div>
  );

export const WarningMessage = ({ text }) => (
  <div className="border border-yellow-400 bg-yellow-50 p-4 select-none">
    <div className="flex">
      <div className="flex-shrink-0 text-yellow-400">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm leading-6 text-yellow-700">{text}</p>
      </div>
    </div>
  </div>
);
