export const updatePlatformMutation = `mutation updatePlatform(
    $ownerId: Int!,
    $labelId: Int!,
    $platform_name: String,
    $subdomain: String,
    $featured_offer: Int,
    $barrier_survey_enabled: Boolean,
    $frequency_survey_enabled: Boolean,
    $section_behavior: String,
    $section_ids: String,
    $primary_colour: String,
    $secondary_colour: String,
    $button_colour: String,
    $button_text_colour: String,
    $bg_color: String,
    $logo_src: String,
    $login_bg_src: String,
    $promo_page_title: String,
    $promo_page_description: String,
    $promo_page_contact_email: String,
    $footer_message: String,
    $show_activation_status: Boolean,
    $show_mortgage_answers: Boolean,
    $show_rating_answers: Boolean,
    $show_access_type: Boolean,
    $allow_csv_export: Boolean
) {  
    updatePlatform(
      input: {
          ownerId: $ownerId,
          labelId: $labelId,
          platform_name: $platform_name,
          subdomain: $subdomain,
          featured_offer: $featured_offer,
          barrier_survey_enabled: $barrier_survey_enabled,
          frequency_survey_enabled: $frequency_survey_enabled,
          section_behavior: $section_behavior,
          section_ids: $section_ids,
          primary_colour: $primary_colour,
          secondary_colour: $secondary_colour,
          button_colour: $button_colour,
          button_text_colour: $button_text_colour,          
          bg_color: $bg_color,
          logo_src: $logo_src,
          login_bg_src: $login_bg_src,
          promo_page_title: $promo_page_title,
          promo_page_description: $promo_page_description,
          promo_page_contact_email: $promo_page_contact_email,
          footer_message: $footer_message,
          show_activation_status: $show_activation_status,
          show_mortgage_answers: $show_mortgage_answers,
          show_rating_answers: $show_rating_answers,
          show_access_type: $show_access_type,
          allow_csv_export: $allow_csv_export
      }
  ) {
    id
  }
}
`;

export const generateAccessKeyMutation = `mutation generateAccessKey($params: String) {  
  generateAccessKey(params: $params) {
    code
  }
}
`;

export const generateSystemAccessCodeMutation = `mutation generateSystemAccessCode {
  generateSystemAccessCode {
    code
    expires_at
  }
}
`;

export const heightenImageMutation = `mutation heightenImage ($file_id: Int!, $height: Int!) {
  heightenImage(file_id: $file_id, height: $height)  {
      id
      public_url
  }
}`;

export const widenImageMutation = `mutation widenImage ($file_id: Int!, $width: Int!) {
  widenImage(file_id: $file_id, width: $width)  {
      id
      public_url
  }
}`;

export const resizeImageCanvasMutation = `mutation resizeImageCanvas ($file_id: Int!, $width: Int!, $height: Int!, $anchor: String!) {
  resizeImageCanvas(file_id: $file_id, width: $width, height: $height, anchor: $anchor)  {
      id
      public_url
  }
}`;

export const resizeImageMutation = `mutation resizeImage ($file_id: Int!, $width: Int!, $height: Int!) {
  resizeImage(file_id: $file_id, width: $width, height: $height)  {
      id
      public_url
  }
}`;

export const updateMessagingConfigMutation = `mutation updateMessagingConfig(
  $account_id: Int!,
  $send_activation_email_1: Boolean!,
  $send_activation_email_2: Boolean!,
  $send_activation_email_3: Boolean!,
  $password_reset_enabled: Boolean!,
  $send_order_confirmation: Boolean!,
  
) {  
  updateMessagingConfig(
    account_id: $account_id,
    send_activation_email_1: $send_activation_email_1,
    send_activation_email_2: $send_activation_email_2,
    send_activation_email_3: $send_activation_email_3,
    password_reset_enabled: $password_reset_enabled,
    send_order_confirmation: $send_order_confirmation
  ) {
    id
    account_id
    send_activation_email_1
    send_activation_email_2
    send_activation_email_3
    password_reset_enabled
    send_order_confirmation
  }
}
`;

export const deleteTermsMutation = `mutation deleteTerms(
  $ids: [Int!]!
) {  
  deleteTerms(      
    ids: $ids
  ) {
    id
  }
}
`;

export const createTermMutation = `mutation createTerm(
  $agency_id: Int!, 
  $new_plan: String!, 
  $terms_url: String!
) {  
  createTerm(
   agency_id: $agency_id,
   new_plan: $new_plan,
   terms_url: $terms_url 
  ) {
    id
  }
}
`;
