import {
  clientQueryFields,
  clientSummaryFields,
  // clientTableFields,
} from "../../configs/clientConfig";
import { summaries, pagination } from "../../configs/types";

// const test = `${
//   clientTableFields().reduce((acc, item) => {
//     return acc += (
//         item.title
//       + '\t'
//       + item.name
//       + '\t'
//       + item.type
//       + '\t'
//       + (item.group || '')
//       + '\t'
//       + (item.filter || '')
//       + '\t'
//       + (item.table_summary || '')
//       + '\t'
//       + (item.gqlSummary || '')
//       + '\t'
//       + (item.in_overview || '')
//       + '\n'
//     )
//   }, '')
// }`
// console.log(test)

const summaryString = `{ ${clientSummaryFields().reduce((acc, item) => {
  return (acc += item.name + summaries[item.gqlSummary] + " ");
}, "")} }`;

const clientString = `{ ${clientQueryFields().reduce((acc, item) => {
  return (acc += item.name + " ");
}, "")} }`;

export const allClientsForDataProvider = `
  query landlordsForDataProvider($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    landlordsForDataProvider(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      email 
      account_name
      subdomain
      firstname
      lastname
      created_at
      updated_at
    }
  }
`;

export const client = `
  query landlordView($id: ID!) { 
    landlordView(id: $id) ${clientString}
  }
`;

export const allClients = `
  query paginatedLandlords($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    paginatedLandlords(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      landlords  ${clientString}
      pagination ${pagination}
      summary    ${summaryString}
    }
  }
`;

export const clientDetails = `
  query landlord($id: ID!) {  
    landlord(id: $id) {
      id
      email
      account_name
      firstname
      lastname
      address
      towncity
      postcode
      telephone
      label_type { id name }
    }
  }
`;

export const clientExtension = `
  query clientExtension($client_id: Int!) { 
    clientExtension(client_id: $client_id) { 
      client_id 
      verbally_agreed_date 
      contract_signed_date 
      contract_end_date 
      final_bill_date 
      contract_term_length 
      service_level 
      data_received_date 
      launch_date 
      number_of_days_to_launch 
      expected_users 
      actual_users 
      launch_number_user_diff 
      campaign_type_id 
      client_type_id 
      status 
      created_at 
      updated_at 
    }
  }
`;

export const clientTypes = `
  query clientTypes($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    clientTypes(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {   
      id
      name
    }
  }
`;

export const campaignTypes = `
  query campaignTypes($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    campaignTypes(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {   
      id
      name
    }
  }
`;

export const landlordOfferExclusionTags = `
  query landlordOfferExclusionTags($account_id: ID!) {  
    landlordOfferExclusionTags(account_id: $account_id) {
        is_active
        tag_object { id slug title }
    }
  }
`;

export const landlordOfferTags = `
  query landlordOfferTags($account_id: ID!) {  
    landlordOfferTags(account_id: $account_id) {
      is_active
      tag_object { id slug title }
    }
  }
`;

export const landlordOffers = `
  query landlordOffers($account_id: ID!) {  
    landlordOffers(account_id: $account_id) {
      is_excluded
      offer_object {
        id
        title
        admin_title
        image
        background
        code
        status
        provider
        start_date
        end_date
        tags          { id slug title }
        exclusionTags { id slug title }
      }
    }
  }
`;

export const allCustomLinks = `
  query customLinks($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    customLinks(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id    
      text
      color
      external
      href
      icon
      display_order
    }
  }
`;

export const customLinkDetails = `
  query customLink($id: ID!) {  
    customLink(id: $id) {
      id
      text
      color
      external
      href
      icon
      display_order
      labels { id name }
    }
  }
`;

export const allDirectRegisterLinks = `
  query directRegisterLinks($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    directRegisterLinks(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id    
      is_enabled
      register_code
      register_link
      max_users_per_register_link
      used_registrations_count
      register_access_type
      register_label_id
      label { 
        id 
        name 
      }
    }
  }
`;

export const directRegisterLinkDetails = `
  query directRegisterLink($id: ID!) {  
    directRegisterLink(id: $id) {
      id    
      is_enabled
      register_code
      max_users_per_register_link
      register_access_type
      register_label_id
      label { 
        id 
        name 
      }
      labels { 
        id 
        name 
      }
      enable_label_select_by_user
    }
  }
`;

export const clientApi = `
  query clientApi($account_id: Int!) {  
    clientApi(account_id: $account_id) {
      account_id
      access_key
      is_enabled
      show_activation_status
      enable_activation_push
    }
  }
`;

export const extensions = `
  query extensions($account_id: Int!) {  
    extensions(account_id: $account_id) {
      account_id
      api_client_users_show_nps
      api_client_users_show_extended_nps
      allow_csv_export
      allow_pdf_report
      allow_pdf_promotion
      notify_about_next_invoice
      notify_email
    }
  }
`;

export const clientEmail = `
  query clientEmail($account_id: Int!, $label_id: Int!) {  
    clientEmail(account_id: $account_id, label_id: $label_id) {
      account_id
      label_id
      logo_src
      primary_colour
      secondary_colour
      button_colour
      button_text_colour
      basic_activation_message_1
      basic_activation_message_2
      basic_activation_message_3
      premium_activation_message_1
      premium_activation_message_2
      premium_activation_message_3
      feedback_campaigns
      custom_feedback
    }
  }
`;

export const paymentSettings = `
  query paymentSettings($account_id: Int!) {  
    paymentSettings(account_id: $account_id) {
      account_id
      type
      maintance_fee_monthly_amount
      maintance_fee_monthly_billing_day
      gifted_premium_access_fee_monthly
      acquired_premium_access_fee_monthly
      minimum_payment_amount
      number_of_free_premium_users
      can_give_basic
      can_give_premium
      can_upgrade
      must_have_payment_method
    }
  }
`;

export const allImports = `
  query imports($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    imports(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      name
      description
      file { original_name } 
      total
      imported
      duplicates
      failed
      unresolved
      status
      message
      created_at
    }
  }
`;

export const importDetails = `
  query import($id: ID!) {  
    import(id: $id) {
      id
      name
      description
      file { original_name } 
      total
      processed
      progress
      imported
      duplicates
      failed
      unresolved
      status
      message
      created_at
    }
  }
`;

export const importFullDetails = `
  query importFullDetails($id: ID!, $queryString: String) {  
    importFullDetails(id: $id, queryString: $queryString) {
      id
      name
      description
      file { original_name } 
      total
      processed
      progress
      imported
      duplicates
      failed
      unresolved
      status
      message
      tenantImports {
        id
        email
        firstname
        lastname
        telephone
        status
        duplicate_of_tenant { id email landlord { id email } }
      }
    }
  }
`;

export const allBulkOperations = `
  query bulkOperations($page: Int!, $limit: Int!, $orderBy: String!, $orderHow: String!, $search: String!, $queryString: String) {  
    bulkOperations(page: $page, limit: $limit, orderBy: $orderBy, orderHow: $orderHow, search: $search, queryString: $queryString) {
      id
      name
      description
      file { original_name } 
      total
      applied
      skipped
      unresolved
      status
      message
    }
  }
`;

export const bulkOperationDetails = `
  query bulkOperation($id: ID!) {  
    bulkOperation(id: $id) {
      id
      name
      description
      file { original_name } 
      total
      processed
      progress
      applied
      skipped
      unresolved
      status
      message
    }
  }
`;

export const bulkOperationFullDetails = `
  query bulkOperationFullDetails($id: ID!, $queryString: String) {  
    bulkOperationFullDetails(id: $id, queryString: $queryString) {
      id 
      name 
      description 
      file { original_name } 
      total 
      processed 
      progress 
      applied 
      skipped 
      unresolved 
      status 
      message 
      details { id operation email status message }
    }
  }
`;
